import React, { type FunctionComponent } from 'react';
import { type ErrorResponse } from 'bb/api/browser/types';
import { useFormErrorMessage, Text } from 'bb/i18n';

type ErrorProps = {
    error: ErrorResponse;
};

export const ExternalPaymentError: FunctionComponent<ErrorProps> = ({
    error
}) => {
    const formError = useFormErrorMessage(error, ['paymentErrors']);
    return (
        <Text size="medium" center>
            {formError}
        </Text>
    );
};
