// @ts-strict-ignore
import React from 'react';
import { PageLayoutFallback } from 'bb/page/PageLayoutFallback';
import { type PageLayout } from 'bb/page/types';
import { PreselectSubscriptionProduct } from './PreselectSubscriptionProduct';
import css from './registrationLayout.module.scss';

export const RegistrationLayout: PageLayout = ({
    children,
    classNames = []
}) => (
    <PageLayoutFallback classNames={[css.base, ...classNames]}>
        <PreselectSubscriptionProduct />
        {children}
    </PageLayoutFallback>
);
