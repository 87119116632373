// @ts-strict-ignore
import { useEffect } from 'react';
import { observer } from 'bb/app/stores';
import { useRegistrationStore } from 'bb/registration/hooks';
import { useSubscriptionStore } from 'bb/subscription';

export const PreselectSubscriptionProduct = observer(() => {
    const subscriptionStore = useSubscriptionStore();
    const registrationStore = useRegistrationStore();
    useEffect(() => {
        if (subscriptionStore?.nrOfSubscriptionProducts === 1) {
            registrationStore.setSubscriptionType(
                subscriptionStore?.subscriptionProducts?.products[0].type
            );
        }
    }, [
        registrationStore,
        subscriptionStore?.nrOfSubscriptionProducts,
        subscriptionStore?.subscriptionProducts?.products
    ]);
    return null;
});
