import React from 'react';
import { useMarket } from 'bb/app';
import { useRouter } from 'bb/app/router';
import { LinkButton, type LinkButtonProps } from 'bb/common/LinkButton';
import { getMarketConfig } from 'bb/config/marketConfig';
import { getDachMarkets } from 'bb/config/marketConfigUtils';
import { Text } from 'bb/i18n';
import { Row, FlagIcon } from 'bb/ui';
import css from './dachMarketSelector.module.scss';

export type DachMarketSelectorProps = {
    linkButtonProps?: Partial<LinkButtonProps>;
};

export const DachMarketSelector = (props: DachMarketSelectorProps) => {
    const { linkButtonProps = {} } = props;
    const { market: appMarket } = useMarket();
    const { router } = useRouter();

    if (!getMarketConfig(appMarket ?? '')?.showDach) return null;

    return (
        <>
            <Row justifyContent="center">
                {getDachMarkets().map((market) => (
                    <LinkButton
                        as="a"
                        key={market}
                        variant="text"
                        reload
                        route="userDetails"
                        query={{ ...router.query, market }}
                        className={css.noPadding}
                        {...linkButtonProps}
                    >
                        <FlagIcon
                            inline
                            size="large"
                            market={market}
                            className={
                                market === appMarket ? '' : css.unselected
                            }
                        />
                    </LinkButton>
                ))}
            </Row>
            <Text
                center
                marginBottom="small"
                t="userDetails:dachChooseCountry"
            />
        </>
    );
};
