import { useEffect } from 'react';
import { useAccountStore } from 'bb/account';
import { useRouter, type Routes } from 'bb/app/router';
import { observer } from 'bb/app/stores';
import { useSubscriptionStore } from 'bb/subscription';
import { match } from 'bb/utils/match';
import { useRegistrationStore } from '../hooks';

interface Redirects {
    routes: Routes;
    isLoggedIn: boolean;
    subscriptionType: string;
    nrOfSubscriptionProducts: number;
    isActivated: boolean;
    isAbandoned: boolean;
    awaitAccountActivation: boolean;
}

type SomeRedirects = Omit<Redirects, 'routes'>;

const redirectToSelectSubscription = ({
    isLoggedIn,
    nrOfSubscriptionProducts,
    subscriptionType
}: SomeRedirects) =>
    isLoggedIn && !subscriptionType && nrOfSubscriptionProducts !== 1;

const redirectToPaymentDetails = ({
    isLoggedIn,
    subscriptionType
}: SomeRedirects) => isLoggedIn && subscriptionType;

const redirectToUserDetails = ({ isLoggedIn }: SomeRedirects) => !isLoggedIn;

const redirectToAwaitAccountActivatation = ({
    awaitAccountActivation
}: SomeRedirects) => awaitAccountActivation;

const redirectToAccountActivated = ({
    isActivated,
    isAbandoned
}: SomeRedirects) => isActivated && !isAbandoned;

export const redirectRoute = <R extends Routes>({
    routes,
    ...redirects
}: Redirects) =>
    match(redirects)
        .on(
            redirectToAwaitAccountActivatation,
            () => routes.awaitAccountActivation
        )
        .on(redirectToAccountActivated, () => routes.accountActivated)

        .on(redirectToUserDetails, () => routes.userDetails)
        .on(redirectToSelectSubscription, () => routes.selectSubscription)
        .on(redirectToPaymentDetails, () => routes.signupSession)
        .otherwise(() => null) as R[keyof R] | null;

export const RegistrationFlow = observer(() => {
    const subscriptionStore = useSubscriptionStore();
    const registrationStore = useRegistrationStore();
    const accountStore = useAccountStore();
    const { navigate, routes, router } = useRouter();

    useEffect(() => {
        const input = {
            isLoggedIn: accountStore.isLoggedIn,
            subscriptionType: registrationStore.subscriptiontype,
            nrOfSubscriptionProducts:
                subscriptionStore.nrOfSubscriptionProducts,
            routes,
            isActivated: accountStore.isActivated,
            isAbandoned: accountStore.isAbandoned,
            awaitAccountActivation: registrationStore.awaitAccountActivation
        };
        const route = redirectRoute<Routes>(input);

        if (route && router.route !== route.href) {
            navigate(route);
        }
    }, [
        accountStore.isActivated,
        accountStore.isAbandoned,
        accountStore.isLoggedIn,
        navigate,
        registrationStore.subscriptiontype,
        router.route,
        routes,
        subscriptionStore.nrOfSubscriptionProducts,
        registrationStore.awaitAccountActivation
    ]);
    return null;
});
