// @ts-strict-ignore
import React, { type FunctionComponent, useEffect } from 'react';
import { useAccountStore } from 'bb/account';
import { useRouter } from 'bb/app/router';
import { observer } from 'bb/app/stores';
import { RedeemGiftcardForm } from 'bb/giftcard';
import { type RedeemGiftcardFormProps } from 'bb/giftcard/components/RedeemGifrcardForm/RedeemGiftcardForm';
import { Text, useTranslation } from 'bb/i18n';
import {
    useActivateAccountWithGiftcard,
    useRegistrationStore
} from 'bb/registration/hooks';
import {
    Box,
    Flex,
    Gap,
    GiftcardIcon,
    Row,
    SelectorButton,
    Spinner
} from 'bb/ui';
import css from './activateAccountGiftcard.module.scss';

type ActivateAccountGiftcardProps = {
    setSelectedForm?: (type: string) => void;
    selectedForm: string;
};

export const ActivateAccountGiftcard: FunctionComponent<ActivateAccountGiftcardProps> =
    observer(({ setSelectedForm, selectedForm }) => {
        const registrationStore = useRegistrationStore();
        const accountStore = useAccountStore();
        const { navigate, routes } = useRouter();
        const { t } = useTranslation([
            'campaign',
            'paymentErrors',
            'subscription'
        ]);
        const isSelected = selectedForm === 'giftcard';
        const [
            giftcardActivationResult,
            giftcardActivationError,
            giftcardActivationIsLoading
        ] = useActivateAccountWithGiftcard(
            registrationStore.giftcard?.code || registrationStore.giftcardCode,
            registrationStore.activateWithGiftcard
        );

        useEffect(() => {
            if (giftcardActivationResult?.account?.isActivated) {
                accountStore.setAccountActivated();
                registrationStore.setCompletedRegistration();
                navigate(routes.accountActivated);
            }
        }, [
            giftcardActivationResult,
            navigate,
            routes.accountActivated,
            accountStore,
            registrationStore
        ]);

        const onOpenGiftCardForm = () => {
            setSelectedForm('giftcard');
        };

        const onGiftcardSubmit: RedeemGiftcardFormProps['onSubmit'] = (
            data
        ) => {
            registrationStore.setActivateWithGiftcard(data?.code);
        };

        return (
            <Box>
                <Gap spacing={3}>
                    {isSelected && (
                        <Text
                            t="paymentErrors:paymentMethodForfeitsTrialDays"
                            center
                        />
                    )}
                    <Row
                        justifyContent="flexStart"
                        alignItems="center"
                        className={css.row}
                    >
                        <Spinner
                            nested
                            ghost
                            show={giftcardActivationIsLoading}
                        />

                        <SelectorButton
                            onClick={onOpenGiftCardForm}
                            label={
                                selectedForm
                                    ? t('subscription:selected')
                                    : t('subscription:select')
                            }
                            selected={Boolean(isSelected)}
                        >
                            <Flex alignItems="center" className={css.border}>
                                <GiftcardIcon inline />
                                <Text
                                    t="common:giftcard"
                                    className={css.text}
                                />
                            </Flex>
                        </SelectorButton>
                    </Row>
                    {isSelected && (
                        <Gap spacing={4}>
                            <Text t="paymentMethod:redeemgiftcard" />
                            <RedeemGiftcardForm
                                disabled={
                                    registrationStore.isWaitingForAdditionalData
                                }
                                onSubmit={onGiftcardSubmit}
                                defaultValues={{
                                    code: registrationStore.giftcard?.code,
                                    termsAndConditions: true
                                }}
                                error={giftcardActivationError}
                                isLoading={giftcardActivationIsLoading}
                                buttonText="paymentMethod:activateGiftCard"
                            />
                        </Gap>
                    )}
                </Gap>
            </Box>
        );
    });
