import React, { type FunctionComponent } from 'react';
import { type Campaign } from 'bb/campaigns/types';
import { isLifetime } from 'bb/campaigns/utils';
import { PriceRuleText } from 'bb/common';
import { Period, Amount, Text, useTranslation } from 'bb/i18n';
import { useFormatAmount } from 'bb/i18n/useFormatAmount';
import type { Product, SubscriptionProducts } from 'bb/subscription/types';
import { Box, Display, List, ListItem } from 'bb/ui';
import { isDef, useFormatDate } from 'bb/utils';

export type PaymentDetailsUspProps = {
    subscriptionProducts: SubscriptionProducts;
    selectedSubscriptionProduct?: Product | null;
    campaign?: Campaign;
    children?: React.ReactNode;
};

const campaignAppliesToCurrentProduct = (
    campaign?: Campaign,
    product?: Product | null
): boolean => {
    const limited = campaign?.priceRules?.[0]?.limitedForProducts;
    const hasCampaignRules = Boolean(campaign?.priceRules?.length);

    if (
        hasCampaignRules &&
        (limited?.length === 0 ||
            (product?.type && limited?.includes(product?.type)))
    ) {
        return true;
    }

    return false;
};

/**
 *
 * @param children <ListItem />
 */
export const PaymentDetailsUsp: FunctionComponent<PaymentDetailsUspProps> = ({
    subscriptionProducts,
    selectedSubscriptionProduct,
    campaign,
    children
}) => {
    const { t } = useTranslation(['common']);
    const product = selectedSubscriptionProduct;
    const trialDays = campaign?.trialDays ?? subscriptionProducts?.trialDays;
    const lifeTimeCampaign = isLifetime({
        days: campaign?.priceRule?.lifeTimeInDays
    });
    const hourLimit =
        campaign?.hourLimit ||
        (!campaign?.code && subscriptionProducts?.trialHours);

    const rawDate = new Date();
    rawDate.setDate(rawDate.getDate() + trialDays);
    const date = useFormatDate(rawDate);

    const priceRule = campaign?.priceRules?.[0];
    const firstProduct = campaign?.products?.[0];
    const showCampaignInfo =
        campaignAppliesToCurrentProduct(campaign, product) &&
        isDef(priceRule?.productDiscount);

    const amountOfMoney = useFormatAmount(firstProduct?.price);

    return (
        <Box style={{ marginLeft: 'auto', marginRight: 'auto' }}>
            <List
                listStyle="disc"
                dataKey="registration-info"
                data-optimizely="payment-details-list"
            >
                {/* TODO: Make more flexible and remove default "children" */}
                {children ?? (
                    <>
                        {campaign?.hasPrepaidDays && (
                            <ListItem dataKey="PaymenDetailsUspZeroTrialDays">
                                <Text
                                    t="registration:zeroTrialDaysUsp"
                                    values={{
                                        amountOfMoney,
                                        amountOfMonths: t('common:nrOfMonths', {
                                            periodLength:
                                                priceRule?.lifeTimeInPeriods ??
                                                1
                                        }),

                                        subscriptionPackage:
                                            firstProduct?.displayName
                                    }}
                                />
                            </ListItem>
                        )}
                        <Display on={trialDays > 0}>
                            <ListItem dataKey="PaymenDetailsUsp1">
                                <Text
                                    values={{ hourLimit }}
                                    t={
                                        hourLimit && hourLimit > 0
                                            ? 'registration:freePremiumHourLimit'
                                            : 'registration:freePremium'
                                    }
                                >
                                    <Period days={trialDays} />
                                    {date}
                                </Text>
                            </ListItem>
                        </Display>
                        {/* if priceRule[0] doesn't have a discount it will not be listed */}
                        {showCampaignInfo && (
                            <ListItem>
                                <PriceRuleText
                                    t={`registration:discountPriceUntil${
                                        lifeTimeCampaign
                                            ? '-lifetimeCampaign'
                                            : ''
                                    }`}
                                    daysFromNow
                                    priceRule={campaign?.priceRules?.[0]}
                                    nrOfDays={campaign?.trialDays}
                                    values={{
                                        subscriptionProduct:
                                            product?.displayName
                                    }}
                                />
                            </ListItem>
                        )}
                        <ListItem dataKey="PaymenDetailsUsp2">
                            <Text
                                t="registration:thereAfter"
                                data-optimizely="payment-thereAfter"
                                values={{
                                    subscriptionProduct: product?.displayName
                                }}
                            >
                                {product?.originalPrice ? (
                                    <Amount amount={product.originalPrice} />
                                ) : null}
                            </Text>
                        </ListItem>
                        <ListItem dataKey="PaymenDetailsUsp3">
                            <Text t="common:quitWhenYouWant" />
                        </ListItem>
                    </>
                )}
            </List>
        </Box>
    );
};
