import React from 'react';
import { type FCChildrenOnly } from 'bb/common/types';
import { TrackingContext } from 'bb/tracker/contexts';
import { RegistrationStoreProvider } from '../hooks/useRegistrationStore';

const context = { context: 'signup' };

export const RegistrationProviders: FCChildrenOnly = ({ children }) => (
    <RegistrationStoreProvider>
        <TrackingContext.Provider value={context}>
            {children}
        </TrackingContext.Provider>
    </RegistrationStoreProvider>
);
