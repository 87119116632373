import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import { useApp } from 'bb/app';
import { useRouter } from 'bb/app/router';
import { getUrl } from 'bb/app/router/utils';
import { ExternalLink } from 'bb/common';
import {
    validGiftcardRegex,
    handleGiftardValueChange
} from 'bb/giftcard/utils';
import {
    type TFunction,
    useFormErrorMessage,
    useTranslation,
    Text
} from 'bb/i18n';
import {
    FormMessage,
    SubmitButton,
    Gap,
    useForm,
    HookFormGiftcardInput,
    type UseHookFormComponentProps,
    HookFormCheckbox
} from 'bb/ui';
import { getSyncDefaultValue } from 'bb/ui/Form/hookForm';
import css from './redeemGiftcardForm.module.scss';

const makeRedeemGiftcardFormSchema = (
    t: TFunction<'giftcard' | 'consents'>,
    showTerms?: boolean
) =>
    z.object({
        code: z
            .string()
            .regex(validGiftcardRegex, t('giftcard:invalidGiftCardCode')),
        termsAndConditions: showTerms
            ? z.literal<boolean>(true, {
                  errorMap: () => ({
                      message: t('consents:consentIsRequired')
                  })
              })
            : z.any()
    });

export type RedeemGiftCardFormSchema = z.infer<
    ReturnType<typeof makeRedeemGiftcardFormSchema>
>;

export type RedeemGiftcardFormProps =
    UseHookFormComponentProps<RedeemGiftCardFormSchema> & {
        buttonText: string;
        showTerms?: boolean;
        /**
         * If true the code field will be disabled.
         *
         * @defaultValue `false`
         */
        disableCodeField?: boolean;
    };

export const RedeemGiftcardForm = ({
    onSubmit,
    onError,
    error,
    disabled = false,
    buttonText,
    isLoading = false,
    showTerms = false,
    defaultValues = {},
    disableCodeField = false,
    ...useFormProps
}: RedeemGiftcardFormProps) => {
    const { t } = useTranslation([
        'common',
        'giftcard',
        'payment',
        'paymentMethod',
        'consents',
        'inputFields'
    ]);
    const { routes } = useRouter();
    const { market } = useApp();

    const { handleSubmit, register, formState } =
        useForm<RedeemGiftCardFormSchema>({
            resolver: zodResolver(makeRedeemGiftcardFormSchema(t, showTerms)),
            defaultValues,
            ...useFormProps
        });

    const { isValid, isDirty, errors, isSubmitting } = formState;

    const formError = useFormErrorMessage(
        error,
        ['common', 'giftcard'],
        'giftcard:redeemGiftcardUnknownError'
    );

    return (
        <form onSubmit={handleSubmit(onSubmit, onError)} className={css.form}>
            <Gap spacing={4}>
                <HookFormGiftcardInput
                    type="text"
                    {...register('code', {
                        onChange(e) {
                            e.target.value = handleGiftardValueChange(
                                e.target.value
                            );
                        }
                    })}
                    maxLength={23}
                    label={t('inputFields:labelGiftCard')}
                    placeholder={t('inputFields:placeholderGiftCard')}
                    fluid
                    error={errors.code}
                    data-testid="redeem-giftcard-form-code"
                    disabled={disableCodeField}
                />

                {!isLoading && error && (
                    <FormMessage
                        status={{
                            message: formError
                        }}
                    />
                )}
                {showTerms && (
                    <HookFormCheckbox
                        {...register('termsAndConditions')}
                        label={
                            <Text t="consents:termsAndConditions">
                                <ExternalLink
                                    href={getUrl(routes.terms.href, {
                                        market
                                    })}
                                />
                                <ExternalLink
                                    href={getUrl(routes.privacyNotice.href, {
                                        market
                                    })}
                                />
                            </Text>
                        }
                    />
                )}
                <SubmitButton
                    fluid
                    text={t(buttonText as Parameters<typeof t>[0])}
                    disabled={disabled || !isValid || isSubmitting}
                    isValid={
                        isValid &&
                        Boolean(
                            isDirty ||
                                getSyncDefaultValue(defaultValues, 'code')
                        )
                    }
                    isSubmitting={isLoading || isSubmitting}
                    data-testid="redeem-giftcard-form-submit"
                />
            </Gap>
        </form>
    );
};
