import React from 'react';
import dynamic from 'next/dynamic';
import { useMagicLink, useVerifyLoggedIn } from 'bb/account';
import { useRouter, useShouldRedirect } from 'bb/app/router';
import { useDeviceInfo } from 'bb/common/hooks';
import { Spinner } from 'bb/ui/Spinner';
import { type AccountActivatedPageProps } from './AccountActivated.types';

const spinner = <Spinner show size="large" />;
const loading = () => spinner;

/**
 * Since we don't rely on media queries in this component
 * we don't need to support both desktop and mobile variants.
 * Lazy load components and render them conditionally to save
 * some kb(s).
 */
const LazyMobileVariant = dynamic(
    () => import('./MobileVariant').then((module) => module.MobileVariant),
    { ssr: false, loading }
);

const LazyDesktopVariant = dynamic(
    () => import('./DesktopVariant').then((module) => module.DesktopVariant),
    { ssr: false, loading }
);

export const AccountActivated = ({
    fallbackRoute,
    ...props
}: AccountActivatedPageProps) => {
    const { routes } = useRouter();
    const { isMobile } = useDeviceInfo();
    const { data } = useVerifyLoggedIn();

    const magicLinkData = useMagicLink();

    useShouldRedirect(
        () => data?.isLoggedIn === false && fallbackRoute !== null,
        fallbackRoute ?? routes.start
    );

    if (!magicLinkData) return spinner;

    return isMobile ? (
        <LazyMobileVariant {...props} magicLinkData={magicLinkData} />
    ) : (
        <LazyDesktopVariant {...props} magicLinkData={magicLinkData} />
    );
};
