import dynamic from 'next/dynamic';
import { withFeatureFlaggedComponent } from 'bb/page/withFeatureFlaggedComponent';

export const ActivateAccount = withFeatureFlaggedComponent(
    dynamic(
        () => import('./ActivateAccount').then((mod) => mod.ActivateAccount),
        {
            ssr: true
        }
    ),
    dynamic(
        () => import('./ActivateAccountV2').then((mod) => mod.ActivateAccount),
        {
            ssr: true
        }
    ),
    'campaign-code-refactor'
);
